






























































































import { Pagination } from 'ant-design-vue';
import { Vue, Component } from 'vue-property-decorator';
import BidForm from './form.vue';
import PayQrcode from './payQrcode.vue';
import moment from 'moment';
import {
  bidItem,
  getBid,
  PageQuery,
  rejectBid,
  UserInfo,
  bidPay,
  bidDetail,
  Params,
  exportBid,
} from '@/api/bid';
import { downloadFile } from '@/utils';

const initRecord: UserInfo = {
  userName: '',
  userMobile: '',
  remark: '',
  sequenceNbr: '',
};

@Component({
  components: { BidForm, PayQrcode },
})
export default class MakeBid extends Vue {
  visible = false;
  qrcodeVisible = false;
  type = 'create';
  loading = false;
  record = initRecord;
  qrcodeData = {
    projectName: '',
    orderAmount: 0,
    qrCodeUrl: '',
    status: '',
  };
  columns = [
    { title: '商务负责人', dataIndex: 'businessManager', width: 150 },
    { title: '联系人', dataIndex: 'userName', width: 150 },
    { title: '联系电话', dataIndex: 'userMobile', width: 150 },
    { title: '需求备注', dataIndex: 'remark', width: 300 },
    { title: '项目名称', dataIndex: 'projectName', width: 240 },
    { title: '企业名称', dataIndex: 'companyName', width: 240 },
    {
      title: '状态',
      dataIndex: 'status',
      width: 120,
      scopedSlots: { customRender: 'status' },
    },
    { title: '创建时间', dataIndex: 'createTime', width: 200 },
    { title: '首付款', dataIndex: 'downPaymentAmount', width: 160 },
    { title: '尾款', dataIndex: 'tailPaymentAmount', width: 160 },
    { title: '首款支付时间', dataIndex: 'downPaymentTime', width: 200 },
    { title: '尾款支付时间', dataIndex: 'tailPaymentTime', width: 200 },
    { title: '介绍人', dataIndex: 'introducer' },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 210,
      scopedSlots: { customRender: 'action' },
    },
  ];
  data: bidItem[] = [];
  pagination = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40', '50'],
    total: 0,
    showQuickJumper: true,
    showTotal: (total: number): string => `共 ${total} 条`,
  };

  status = {
    '': '全部',
    NEW_ORDER: '待接单',
    TO_BE_CONFIRMED: '已补录',
    DOWN_PAYMENT: '已支付首款',
    TAIL_PAYMENT: '已支付尾款',
    REJECT: '已拒单',
  };

  params: PageQuery = {
    userName: '',
    userMobile: '',
    status: '',
    createTime: [],
    downPaymentTime: [],
    tailPaymentTime: [],
  };

  created(): void {
    this.fetchData();
  }

  transformDate(time: string[]): string[] {
    const [start = '', end = ''] = time;
    if (start) {
      return [start + ' 00:00:00', end + ' 23:59:59'];
    }
    return time;
  }

  transformQuery(page?: number): Params {
    const { createTime, downPaymentTime, tailPaymentTime, ...other } =
      this.params;
    const [createStartTime = '', createEndTime = ''] =
      this.transformDate(createTime);
    const [downPaymentStartTime = '', downPaymentEndTime = ''] =
      this.transformDate(downPaymentTime);
    const [tailPaymentStartTime = '', tailPaymentEndTime = ''] =
      this.transformDate(tailPaymentTime);
    return {
      page: page || this.pagination.current,
      limit: this.pagination.pageSize,
      createStartTime,
      createEndTime,
      downPaymentStartTime,
      downPaymentEndTime,
      tailPaymentStartTime,
      tailPaymentEndTime,
      ...other,
    };
  }

  transformTime(time: string): string {
    return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '';
  }

  fetchData(page?: number): void {
    this.loading = true;
    getBid(this.transformQuery(page))
      .then((res) => {
        const { total, list } = res.result;
        this.pagination.total = total;
        this.data = list.map((item) => ({
          ...item,
          createTime: this.transformTime(item.createTime),
          downPaymentTime: this.transformTime(item.downPaymentTime),
          tailPaymentTime: this.transformTime(item.tailPaymentTime),
        }));
        if (page) {
          this.pagination.current = page;
        }
      })
      .finally(() => (this.loading = false));
  }

  showForm(type: string, record?: UserInfo): void {
    this.visible = true;
    this.type = type;
    this.record = record
      ? {
          userName: record.userName,
          userMobile: record.userMobile,
          remark: record.remark,
          sequenceNbr: record.sequenceNbr,
        }
      : initRecord;
  }

  async payCode(record: bidItem, type = ''): Promise<void> {
    const res = await bidDetail(record.sequenceNbr);
    if (
      ['TO_BE_CONFIRMED', 'DOWN_PAYMENT'].includes(record.status) &&
      record.status !== res.result.status
    ) {
      this.$message.error(
        res.result.status === 'DOWN_PAYMENT'
          ? '该订单首款已支付'
          : '该订单尾款已支付'
      );
      record.status = res.result.status;
      return;
    }
    let qrcodeData = {
      projectName: record.projectName,
      status: record.status,
      orderAmount:
        record.status === 'TO_BE_CONFIRMED'
          ? record.downPaymentAmount
          : record.tailPaymentAmount,
      qrCodeUrl: '',
    };
    if (type === 'h5') {
      console.log(process.env);
      qrcodeData.qrCodeUrl = `${process.env.VUE_APP_PORTAL_BROCHURE_H5_URL}/biddingCost?id=${record.sequenceNbr}`;
      this.qrcodeData = qrcodeData;
      this.qrcodeVisible = true;
      return;
    }
    bidPay(record.sequenceNbr).then((res) => {
      qrcodeData.qrCodeUrl = res.result.qrCodeUrl;
      this.qrcodeData = qrcodeData;
      this.qrcodeVisible = true;
    });
  }

  handleChange(pagination: Pagination): void {
    const { current, pageSize } = pagination;
    Object.assign(this.pagination, { current, pageSize });
    this.fetchData();
  }

  reject(record: bidItem): void {
    this.$confirm({
      title: '确定要拒单吗？',
      onOk: () => {
        rejectBid(record.sequenceNbr).then(() => {
          record.status = 'REJECT';
          this.$message.success('操作成功');
        });
      },
    });
  }

  reset(): void {
    this.params = {
      userName: '',
      userMobile: '',
      status: '',
      createTime: [],
      downPaymentTime: [],
      tailPaymentTime: [],
    };
    this.fetchData(1);
  }

  exportExcel(): void {
    this.loading = true;
    exportBid(this.transformQuery())
      .then((res: ArrayBuffer) => {
        downloadFile(
          res,
          '标书代做渠道订单表' + moment().format('YYYYMMDDHHmmss')
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
